import {twMerge} from '@/stylesheets/twMerge';
import Card, {
  type CardProps,
} from '@/pages/shopify.com/($locale)/_index/components/Card/Card';
import POSLoader, {
  type POSLoaderProps,
} from '@/pages/shopify.com/($locale)/_index/components/POS/POSLoader';
import AutoResponsiveImage, {
  type AutoResponsiveImageProps,
} from '@/components/base/elements/AutoResponsiveImage/AutoResponsiveImage';

export interface POSCardProps extends CardProps, POSLoaderProps {
  staticImage?: AutoResponsiveImageProps;
}

export default function POSCard({
  className,
  handheldImageSrc,
  image,
  tabletImageSrc,
  staticImage,
  ...props
}: POSCardProps) {
  return (
    <Card {...props} className={twMerge(className, 'relative')}>
      <div className="sm:h-[600px] md:h-full">
        {staticImage?.src ? (
          <AutoResponsiveImage
            {...staticImage}
            sizes="(min-width: 900px) 33vw, 100vw"
          />
        ) : (
          <POSLoader
            image={image}
            handheldImageSrc={handheldImageSrc}
            tabletImageSrc={tabletImageSrc}
          />
        )}
      </div>
    </Card>
  );
}
