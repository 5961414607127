import type {ComponentType, LazyExoticComponent} from 'react';
import {useEffect, useRef, useState, lazy, useCallback} from 'react';
import cn from 'classnames';

import useReduceMotion from '@/hooks/useReducedMotion';
import AutoResponsiveImage, {
  type AutoResponsiveImageProps,
} from '@/components/base/elements/AutoResponsiveImage/AutoResponsiveImage';

import {type POSProps} from '../POS/POS';
import {type DetectGpuProps} from '../DetectGpu/DetectGpu';

export interface POSLoaderProps {
  image: AutoResponsiveImageProps;
  handheldImageSrc?: string;
  tabletImageSrc?: string;
}

export default function POSLoader({
  handheldImageSrc,
  image,
  tabletImageSrc,
}: POSLoaderProps) {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const [isLowEndMachine, setIsLowEndMachine] = useState<boolean | null>(null);
  const reduceMotion = useReduceMotion(false);
  const [isPOSRendered, setIsPOSRendered] = useState(false);
  const [hasLoadedDetectGpu, setHasLoadedDetectGpu] = useState(false);
  const [hasLoadedPOS, setHasLoadedPOS] = useState(false);

  const POSLazyLoaded = useRef<LazyExoticComponent<
    ComponentType<POSProps>
  > | null>(null);
  const DetectGpuLazyLoaded = useRef<LazyExoticComponent<
    ComponentType<DetectGpuProps>
  > | null>(null);

  const POSLazyLoadedComponent = POSLazyLoaded.current;
  const loadDeferredPOS = useCallback(() => {
    POSLazyLoaded.current = lazy(
      () => import('@/pages/shopify.com/($locale)/_index/components/POS/POS'),
    );
    setHasLoadedPOS(true);
  }, []);

  const DetectGpuLazyLoadedComponent = DetectGpuLazyLoaded.current;
  const loadDeferredDetectGpu = useCallback(() => {
    DetectGpuLazyLoaded.current = lazy(
      () =>
        import(
          '@/pages/shopify.com/($locale)/_index/components/DetectGpu/DetectGpu'
        ),
    );
    setHasLoadedDetectGpu(true);
  }, []);

  /**
   * Load DetectGpu component after component is scroll within 100% of viewport
   * Note: not using useIntersectionObserver hook because it's firing multiple times
   * as the component re-renders
   */
  useEffect(() => {
    if (hasLoadedDetectGpu || !intersectionRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            !hasLoadedPOS &&
            !hasLoadedDetectGpu &&
            !reduceMotion &&
            entry.isIntersecting
          ) {
            loadDeferredDetectGpu();
          }
        });
      },
      {rootMargin: '100% 0% 100% 0%'},
    );
    observer.observe(intersectionRef.current);

    return () => observer.disconnect();
  }, [hasLoadedDetectGpu, reduceMotion, loadDeferredDetectGpu, hasLoadedPOS]);

  /**
   * Load POS component after DetectGpu component has detected the GPU
   */
  useEffect(() => {
    if (hasLoadedDetectGpu && !hasLoadedPOS && isLowEndMachine === false) {
      loadDeferredPOS();
    }
  }, [hasLoadedDetectGpu, hasLoadedPOS, loadDeferredPOS, isLowEndMachine]);

  return (
    <div
      ref={intersectionRef}
      className="h-full w-full absolute sm:static sm:px-auto sm:w-auto"
    >
      {image?.src && (
        <AutoResponsiveImage
          {...image}
          loading="lazy"
          className={cn(
            'h-full mx-auto duration-500 object-contain',
            'xs:scale-[1.1]',
            'sm:scale-[1.25]',
            'md:scale-[0.8]',
            'lg:scale-[0.93]',
            'xl:scale-[1.03]',
            {
              'opacity-100': !isPOSRendered,
              'opacity-0': isPOSRendered,
            },
          )}
          sizes="(min-width: 900px) 33vw, 100vw"
        />
      )}
      {hasLoadedDetectGpu && DetectGpuLazyLoadedComponent && (
        <DetectGpuLazyLoadedComponent setIsLowEndMachine={setIsLowEndMachine} />
      )}
      {hasLoadedPOS && POSLazyLoadedComponent && (
        <POSLazyLoadedComponent
          handheldImageSrc={handheldImageSrc}
          setIsPOSRendered={setIsPOSRendered}
          tabletImageSrc={tabletImageSrc}
        />
      )}
    </div>
  );
}
