import {useRef, useState} from 'react';
import cn from 'classnames';

import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import HeadingGroup from '@/pages/shopify.com/($locale)/_index/components/HeadingGroup/HeadingGroup';
import Section from '@/pages/shopify.com/($locale)/_index/components/Section/Section';
import type {HomeHeadingGroupProps} from '@/pages/shopify.com/($locale)/_index/components/HeadingGroup/HeadingGroup';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import AutoResponsiveImage, {
  type AutoResponsiveImageProps,
} from '@/components/base/elements/AutoResponsiveImage/AutoResponsiveImage';

interface CapitalSectionQuote {
  author: string;
  authorTitle: string;
  quoteHtml: string;
}

export interface CapitalSectionProps {
  sectionName: string;
  headingGroup: HomeHeadingGroupProps;
  image?: AutoResponsiveImageProps;
  quote?: CapitalSectionQuote;
  stats: HomeHeadingGroupProps[];
  disclaimerHtml?: string;
}

const TRANSITION_DURATION = 1000;
const TRANSITION_STEP = {
  IDLING: 'idling',
  INTERSECTED: 'intersected',
};

const idlingClassNames = 'opacity-0 translate-x-4';
const intersectedClassNames = 'opacicity-100 tranlsate-x-0';

export default function CapitalSection({
  sectionName,
  headingGroup,
  image,
  quote,
  stats,
  disclaimerHtml,
}: CapitalSectionProps) {
  const [transitionStep, setTransitionStep] = useState(TRANSITION_STEP.IDLING);
  const statsRef = useRef<HTMLDivElement>(null);

  useIntersectionObserver(
    statsRef,
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          setTransitionStep(TRANSITION_STEP.INTERSECTED);
        }
      });
    },
    {
      rootMargin: '-100px',
    },
  );

  const ellipseOneClassNames =
    'with-blurred-ellipse before:bottom-0 before:left-[-12.5%] before:rotate-45 before:scale-[1.5]';
  const ellipseTwoClassNames =
    'with-blurred-ellipse before:top-0 before:right-[20%] before:rotate-45 before:scale-[1.5]';

  const transitionClassNames =
    transitionStep === TRANSITION_STEP.IDLING
      ? idlingClassNames
      : intersectedClassNames;

  return (
    <Section
      bottomSpacing="none"
      className={cn(
        'bg-deep-pine py-3xl sm:py-4xl overflow-hidden',
        ellipseOneClassNames,
      )}
      sectionName={sectionName}
      topSpacing="2xl"
      data-component-name="home-capital-section"
    >
      <TwoColumn className="items-center">
        <TwoColumn.Col1 className="order-2 sm:col-span-4 sm:order-1 sm:h-full sm:pr-[7.5%] md:pr-0">
          <div
            className={cn(
              'relative max-sm:aspect-[382/290] w-full sm:h-full',
              ellipseTwoClassNames,
            )}
          >
            {image && (
              <AutoResponsiveImage
                className="absolute h-2/3 left-0 object-cover rounded-lg w-3/5 sm:h-[70%] sm:-top-6 sm:max-w-3/4 md:h-3/5 md:-top-8 md:max-w-3/5 lg:h-3/4 lg:w-1/2 xl:left-10 xl:w-[45%]"
                {...image}
                sizes="(min-width: 640px) and (max-width: 899px) 37.5vw, (min-width: 1200) and (max-width: 1599px) 25vw, (min-width: 1600) 22.5vw, (max-width: 639px) 30vw"
              />
            )}
            {quote && (
              <div className="absolute bg-[#072720] border border-[#133B32] bottom-0 p-6 right-0 rounded-lg w-3/4 md:p-9 md:w-4/5 lg:w-3/4 xl:w-4/5">
                <blockquote className="after:content-['”'] before:absolute before:content-['“'] before:-left-[0.3em] before:top-0 relative text-t5">
                  {quote.quoteHtml}
                </blockquote>
                <footer className="[&_a]:underline mt-4 text-sm md:mt-8 md:text-lg">
                  <p>{quote.author}</p>
                  <p dangerouslySetInnerHTML={{__html: quote.authorTitle}} />
                </footer>
              </div>
            )}
          </div>
        </TwoColumn.Col1>
        <TwoColumn.Col2 className="gap-y-0 order-1 sm:col-span-4 sm:col-start-5 sm:order-2">
          <HeadingGroup
            headingAs="h3"
            headingClassName="mb-md"
            headingHtml={headingGroup.headingHtml}
            size="t4"
            subheadClassName="!text-b3 text-gray-b"
            subheadHtml={headingGroup.subheadHtml}
            subheadSize="body-sm"
          />
          <div className="pt-8 md:pt-12 space-y-lg" ref={statsRef}>
            {stats.map((stat, index) => (
              <div
                className={transitionClassNames}
                key={index}
                style={{
                  transitionDelay: `${(TRANSITION_DURATION / 2) * index}ms`,
                  transitionDuration: `${TRANSITION_DURATION}ms`,
                }}
              >
                <HeadingGroup
                  className="border-l-2 border-avocado pl-[1em] pb-0 transition-all"
                  headingAs="p"
                  headingClassName="mb-xs"
                  headingHtml={stat.headingHtml}
                  size="t5"
                  subheadClassName="text-gray-b"
                  subheadSize="body-sm"
                  subheadHtml={stat.subheadHtml}
                />
              </div>
            ))}
            {disclaimerHtml && (
              <div
                className="text-gray-b text-b6 pl-[5px] indent-[-5px] [&_a]:underline"
                dangerouslySetInnerHTML={{__html: disclaimerHtml as string}}
              ></div>
            )}
          </div>
        </TwoColumn.Col2>
      </TwoColumn>
    </Section>
  );
}
